body, p, a, li, label, tr, input, textarea, dt, dd, span, h1, h2, h3, h4, button {
	font-family: 'Noto Sans', sans-serif !important;
}

.s4s-default input[type=text], 
.s4s-default input[type=password], 
.s4s-default input[type=email], 
.s4s-default input[type=tel], 
.s4s-default input[type=phone], 
.s4s-default input[type=date], 
.s4s-default textarea {
  box-shadow: none;
  border: 2px solid #c5c5c5;
  border-radius: 0;
  padding: 0.5rem;
}

.s4s-default .border,
.pcg-page-wrapper .s4s-default .border {
  border: none !important;
}

.pcg-page-wrapper .select2-results__options {
  padding-left: 0;
}

.s4s-default .tint2,
.s4s-default .tint3,
.s4s-default .tint4 {
  background: none;
}

.s4s-default .round {
  border-radius: 0;
}

dl.summary.wide dd {
	padding: 10px 0 10px 315px;
}	

a.external-link {
  &:after {
      content: '\f35d'; /* FontAwesome icon for external link */
      font-family: 'Font Awesome 5 Free'; /* Specify the font family for FontAwesome */
      font-weight: 900; /* Ensure the icon is solid */
      margin-left: 4px;
      font-size: 0.7rem;
  }
}

.PPL-registration-details{

  .full-columnAlt {
    margin-left: 0;
  }

  .col {
    float: none !important;
    width: 100% !important;
  }

  &.s4s-default dl.summary {
    float: none;
    font-size: 1rem;
    margin: 0;

    display: flex;
    flex-wrap: wrap;

    dt {
      width: 40%;
      position: relative;
      left: inherit;
      padding: 1rem 2rem;
      line-height: 1.2;
      border-bottom: 1px solid #fff;
    }

    dd {
      width: 60%;
      padding: 1rem 2rem;
      line-height: 1.2;
      margin-bottom: 0;
    }
  }

  .form-app {

    margin-top: 4rem;
    margin-bottom: 2rem;

    .form-header {
      text-align: left;

      h2 {
        font-weight: bold;
      }
    }
  }

	#maincontent{
		margin-top: 4rem;
	}	

	.span_6_of_12{
		width: 70%;
	}

	.pcgf-default .form-navigation-btns__container{
		margin-top: 3rem;
		background: none;
		box-shadow: none;
	}

	.pcgf-default .form-main__questions-container{
		margin: 0;
	}
}

.PPL-registration-confirmation{

  #forgotPassPromo{
    width: 100%;
  }

  #forgotPassPromo h1{
    height: auto;
    font-size: 2rem;
    color: #000;
    font-weight: normal;
  }

  #forgotPassPromo h2{
    height: auto;
    font-size: 1.5rem;
    color: #000;
    font-weight: normal;
    margin: 2rem 0;
    line-height: 2rem;
  }

  #forgotPassPromo p{
    color: #000;
    line-height: 2rem;
  }


  .contentFixed.loginPage {
    width: 100% !important;
    margin: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #LoginBox {
      padding: 1rem;
      border-radius: 0px;
      box-shadow: none;
      background-color: #edf0f3;
      text-align: center;
      width: 100%;
      margin: 2rem 10%;
      display: flex;
      flex-direction: row;
  }

  .contentWrapper.loginBackground{
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
  }



}

.pcg-page-wrapper {
  ul.account {
   padding-left: 0;
   margin-bottom: 0;
 
   ul {
     padding-left: 0 !important;
     margin-bottom: 0;
   }
 }

 ol.document-helper {
   list-style: lower-alpha;
   list-style-position: inside;
   margin-bottom: 1rem;
   padding: 1rem;
   background-color: #f7f7f7;
 
   li {
     margin-bottom: 0.5rem;
 
     &:last-child {
       margin-bottom: 0;
     }
   }
 }

 .document-helper p{
  margin-bottom: 2rem !important;
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f7f7f7;
  max-width: 100% !important;
 }

}


.s4s-default {

  .entityForm {
    display: flex;
    flex-direction: column;

    ul.actionButtons {
      order: 1;
    }
  }

  ul.actionButtons {
  
    margin: 0;
    padding: 0;
  
    li.secondary a,
    a {
      background: none !important;
      background-color: white !important;
      color: #07073f !important;
      border: 2px solid #07073f;
      text-shadow: none !important;
      font-weight: normal !important;
      padding: 7px 20px 6px 20px;
  
      span {
        display: none;
      }
    }
  
    li.primary a {
      background: none !important;
      background-color: #07073f !important;
      color: white !important;
    }
  }

  .attribute-box__container {

    max-width: 700px;

    > .section {
      margin-bottom: 0;
    }

    .attribute-box {
      float: none;
      margin: 0;
      width: 100%;

      fieldset {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        border-bottom: 1px solid #ececec;
        margin-top: 1rem;

        .amendArrow {
          position: absolute;
          right: 0.5rem;
          top: -33px;
          float: none;

          &:hover {
            cursor: pointer;
          }
        }

        .isSelectedAll {
          // position: absolute;
          // left: 1rem;
          // top: -33px;
          display: none;
        }

        legend {
          background: rgb(236, 236, 236);
          padding: 1rem 2.6rem 1rem 1rem;
          float: none;
          margin-bottom: 0;
        }

        .section {
          width: 100%;
          // display: flex;
          // align-items: center;
          // gap: 1rem;
          position: relative;
          padding: 1rem;
          margin: 0;
          border-left: 1px solid #ececec;
          border-right: 1px solid #ececec;

          input {
            position: absolute;
            left: 1rem;
            top: 21px;
            float: none;
          }

          label {
            padding-left: 1.7rem;
            margin: 0;
          }
        
          // &:last-child {
          //   border-bottom: 1px solid #ececec;
          // }
        }
      }
    }

    // Check boxes and radios
    [type="checkbox"]:checked~label:before,
    [type="checkbox"]:not(:checked)~label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 2px solid #a0a0a0;
      border-radius: 0;
      background: #fff;
    }

    [type="checkbox"]:checked~label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 0;
      background: #fff;
    }

    [type="checkbox"]:checked~label:after,
    [type="checkbox"]:not(:checked)~label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: #127c85;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 0;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
    }

    [type="checkbox"]:checked~label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: #09093f;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #09093f, 4px 0 0 #09093f, 4px -2px 0 #09093f, 4px -4px 0 #09093f, 4px -6px 0 #09093f, 4px -8px 0 #09093f;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    [type="checkbox"]:checked~label:before {
      background: #ffffff;
    }

    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="checkbox"]:checked~label,
    [type="checkbox"]:not(:checked)~label,
    [type="checkbox"]:checked~label,
    [type="checkbox"]:not(:checked)~label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #484848;
    }

    [type="checkbox"]:not(:checked)~label:after,
    [type="checkbox"]:not(:checked)~label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    // Check boxes and radios end

  }

  .directory-settings__container {

    > ul li.ValidateDropdown {
      padding: 0 !important;
      margin: 0;
    }
  }

  .isPublished__container {
    padding: 2rem !important;
    border: 2px solid #09093f !important;;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    label {
      margin-bottom: 0 !important;
    }

    p{
      max-width: 100% !important;
      margin: 0 !important;
      width: 100% !important;
    }
  }

  .js-image-upload-container {
    legend {

      &:after {
        content: 'Please ensure your image is in JPEG format and under 20Kb in size';
        display: block;
        font-weight: normal;
        font-size: 0.9rem;
      }
    }
  }

  .company-video-guidance{
    legend {
      &:after {
        content: 'Please insert link to a YouTube video to be displayed on your profile';
        display: block;
        font-weight: normal;
        font-size: 0.9rem;
      }
    }
  }

  // Forms
  .form-default {

    .form-header {
      text-align: left;
      margin: 2rem 0 4rem 0;
    }
    
    .form-main__questions-container {
      padding: 0;

      input[type=text], 
      input[type=password], 
      input[type=number], 
      input[type=date], 
      textarea, 
      select {
        box-shadow: none;
        border: 2px solid #c5c5c5;
        border-radius: 0;
        padding: 0.5rem;
      }

    }

    .readonly-field {

      position: relative;

      .characters-countdown-input_label {
        display: none;
      }

      input {
        padding: 0 !important;
        border: none !important;
        font-weight: bold;
        font-size: 1.2rem;
        
      }

      &:after {
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background-color: transparent;
        top: 0;
        left: 0;
      }
    }

    .hide-field {
      visibility: hidden;
      height: 1px;
      margin-bottom: 0 !important;

      label {
        display: none;
      }
    }

    .star-ratings {
      fieldset {
        display: flex;
        flex-wrap: wrap;

        &:focus{
          border: 2px solid black;
        }

        legend {
          width: 100%;
        }

        .form-radio__option {
          width: 30px;
          height: 30px;
          margin-right: 0.5rem;
          
          label {
            padding-left: 0 !important;
            text-align: center;
            display: flex;
            flex-direction: column;
            float: none;
            line-height: inherit;
          }

          

          [type="radio"]:checked+label:before,
          [type="radio"]:not(:checked)+label:before {
            font-family: FontAwesome;
            content: "\f005";
            position: relative;
            border: none;
            border-radius: 0;
            background: transparent;
            color: #fff;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
            width: 30px;
            height: 30px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #07073f;
          }

          /* Fallback for Firefox */
          @-moz-document url-prefix() {
            [type="radio"]:checked+label:before,
            [type="radio"]:not(:checked)+label:before {
                font-family: Arial, sans-serif; /* Use a fallback font */
                content: "\2605"; /* Unicode character for a star */
                text-shadow: 0px 0px 8px black; /* Optional text shadow */
            }
          }

          &.selected {
            [type="radio"]+label:before {
              color: #07073f;
            }
            [type="radio"]:checked+label:before {
              color: #07073f;
            }
          }


          [type="radio"]:checked+label:after,
          [type="radio"]:not(:checked)+label:after {
            content: '';
            background: transparent;
          }

        }
      }
    }



    .form-navigation-btns__container {
      position: relative;
      bottom: inherit;
      left: inherit;
      height: inherit;
      padding-top: 0;
      background: transparent;
      text-align: left;
      box-shadow: none;
      display: flex;
      gap: 0.5rem;

      button {
        background-color: #07073f;
        border: 2px solid #07073f;
        color: white;
        padding: 0.5rem 2rem;
        text-shadow: none;
        border-radius: 0;

        &:hover {
          background-color: white;
          color: #07073f;
          text-decoration: none;
        }
      }

    }
  }


  // Modal boxes
  .popup-container {
    border-radius: 0;

    .form-address-search__custom-address-form__header {
      color: black;
    }

    
    .form-control {
      margin-bottom: 1rem;
      position: relative;

      label {
        line-height: 1.4;
        font-weight: bold;
        width: inherit;
        float: none;
        display: block;
        max-width: 700px;
        margin: 0 0 0.2rem 0;
        padding: 0;
      }

      input {
        width: 100%;
        max-width: 700px;
      }
    }

    .pcgf-action {

      &.pcgf-action--close {
        position: absolute;
        right: 2rem;
        background: none;
        text-decoration: none;
        border: 2px solid black;

        &:after {
          font-family: FontAwesome;
          content: "\f00d";
          color: #000;
          padding: 3px;
          font-size: 1rem;
        }
      }
    }
  }

}


.PPL-registration {
  #administrationExcerpt {
    margin-top: 4rem;
  }
}

.s4s-default {

  .contentWrapper {
    float: none;
  }

  .adminBox {
    // margin-top: 4rem;
    margin: 0;
    background: none;
    background-color: #07073f;
    border-radius: 0;
    box-shadow: none;

    ul#tree {
      padding-left: 0;
    }
  }

  #administrationExcerpt,
  #myLifeExcerpt {
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
    background: none;

    h1 {
      color: #07073f;
      font-size: 2rem;
    }
  }

  #myLifeExcerpt {
    > p {
      display: none;
    }
  }

  .supportSteps {
    margin-left: 0;
  }

  .clearFixBorder {
    display: none;
  }

  .gridHeader {

    &.admin {
      th {
        background: none;
        background-color: #e6e6e6;
        color: black;

        h1 {
          font-size: 1rem;
          margin-bottom: 0;
        }

        a {
          color: black;
          text-decoration: underline;
        }
      }
    }
  }

  .bottomRibbon {
    position: relative;
    height: inherit;
    bottom: inherit;
    left: inherit;
    background: none;
    // padding-left: 1rem;
    margin-bottom: 2rem;
    box-shadow: none;
    width: fit-content;

    .blockBtns {
      float: none;
    }
  }

  .blockBtns {
    background: none !important;
    background-color: white !important;
    color: #07073f !important;
    text-shadow: none !important;
    font-weight: normal;
    border-radius: 0;
    font-size: 1rem !important;
    padding: 1rem 2rem !important;
    border: 2px solid #07073f !important;
    border-bottom: 2px solid #07073f !important;

    &.green {
      background-color: #07073f !important;
      color: white !important;
    }

    &:hover {
      color: white;
    }

    &:focus {
      margin-top: 0 !important;
      // text-decoration: none !important;
    }

    img,
    i {
      display: none;
    }
  }

  .pagination {
    display: flex;

    > span {
      line-height: 1.4;
      margin-top: 0;

      .input {
        width: 28px;
      }
    }
  }

  .articleFull {
    min-width: inherit;
    margin: 0;
  }

  #accordion {
    li {
      padding: 0 2.5rem;
    }
  }
}

.s4s-default .yellowBtn, 
.s4s-default .redBtn, 
.s4s-default .greenBtn {
  background: none;
  background-color: #07073f;
  border-radius: 0;
  text-transform: none;
}

table.grid tbody td {
    word-break: break-word;
}

// Tiny MCE
.s4s-default {

  .mce-tinymce {
    border-width: 2px !important;
  }

  .mce-container {
    margin-left: 0 !important;
    max-width: 700px;
    clear: none;
    border-color: #c5c5c5;
  

    * {
      border-color: #c5c5c5;
    }

    &.mce-floatpanel {
      max-width: inherit;
      background: white;
    }
  }

  .mce-panel {
    background: none;

    .mce-btn {
      background: none !important;
      background-color: white !important;
    }

    // Buttons bar
    &.mce-first {
      background: #f7f7f7;
    }

    // Textarea
    &.mce-edit-area {
      padding: 1rem 1rem 0 1rem;
      border: none;
    }

    // Footer
    &.mce-statusbar {
      border: none !important;
    }

    #mceu_25,   // Undo/Redo
    #mceu_31,   // Preview
    #mceu_28,   // Align options
    #mceu_33,   // Full screen
    #mceu_34 {  // Upload html
      display: none
    }

    
  }

  


  // Additional fields
  // .mce-tinymce-inline {
  //   display: none;
  // }
  // .mce-toolbar {

  //   .mce-container-body {
  //     display: flex;
  //     flex-wrap: wrap;
  //   }

  //   #mceu_60,
  //   #mceu_63,
  //   #mceu_67,
  //   #mceu_68 {
  //     display: none;
  //   }
  // }
  
  
}

.all-buttons{
  #mceu_25,   // Undo/Redo
  #mceu_31,   // Preview
  #mceu_28,   // Align options
  #mceu_33,   // Full screen
  #mceu_34 {  // Upload html
    display: inline-block !important;
  }
}




.create-directory{

	#maincontent{
		width: 80%;
    	margin: 4rem auto;
	}	

	.documents__editor{
		flex-direction: column;
		row-gap: 2rem;

		.lhs{
			margin-left: 0;
		}
	}
	
	.documents__tree {
		margin-top: 2rem;	
	}

	.inline-editor label{
		width: 90px !important;
	}
}


// .PPL-events {
.right-columnAlt {
  margin-left: 0;
  max-width: inherit;
  margin: 0 auto;
}

.left-columnAlt,
.s4s-default .bottom {
  display: none;
}

#maincontent{
  margin-top: 4rem;
}	

.gridHeader.admin th {
  background: -webkit-gradient(linear,left top,left bottom,from(#000),to(#352d2d));
}
// }



//.PPL-events-details{

#maincontent{
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  margin: 2rem auto;
}	

.user-management {
  #maincontent {
    display: flex;
    flex-direction: column;

    .contentWrapper {
      order: 2;
    }

    .left-columnAlt {
      order: 1;
      width: 100%;

      #tree {
        display: flex;
        margin: 0;
        gap: 2rem;
      }
    }
  }
}

.listing-guidance{
  margin-bottom: 2rem;
  font-weight: bold;
  p{
    color: #000 !important;
  }
}

@media (min-width: 576px) {
  #maincontent{
    max-width: 540px;
  }	
}

@media (min-width: 768px) {
  #maincontent{
    max-width: 720px;
  }	
}

@media (min-width: 1024px) {
  #maincontent{
    max-width: 960px;
  }	
}

@media (min-width: 1200px) {
  #maincontent{
    max-width: 1140px;
  }	
}

@media (min-width: 1400px) {
  #maincontent{
    max-width: 1320px;
  }	
}

.s4s-default #administrationExcerpt {
  margin: 0;
  margin-bottom: 2rem;
  float: none;
}

.s4s-default .right-columnAlt {
  min-width: inherit;
}

.s4s-default .pad20,
.s4s-default .contentPadding {
  padding: 0;
}

.s4s-default .liquid-round,
.s4s-default #liquid-round {
  margin: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: none;
}

.s4s-default .top {
  background: none;
  display: none;
}

.s4s-default .center-content,
.s4s-default .center-content-no-top {
  background: none;
  padding: 0;
  margin: 0;
}

.s4s-default .mar-left1 {
  margin-left: 0;
}

.s4s-default .mar-right1 {
  margin-right: 0;
}

.s4s-default .right-columnAlt{
  margin-left: 0;
}

.s4s-default .left-columnAlt {
  // display: none;
  display: block;
  float: none;
  margin-left: 0;
}

#accordion h2 > a:before {
  content: "";
}

.s4s-default ul.account {

  legend,
  label {
    line-height: 1.4;
    font-weight: bold;
    width: inherit;
    float: none;
    display: block;
    max-width: 550px;
    margin: 0 0 0.5rem 0;
    padding: 0;

    small {
      display: block;
      font-weight: normal;
      font-size: .9rem;
    }
  }

  input {
    width: 100%;
    max-width: 700px;
  }

  li.ValidateAltNorm,
  li.ValidateAltSmall {
    margin-bottom: 2rem;

    > .mar-bot1 {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  li.blocks {
    margin: 0 !important;
    padding: 0 !important;

    li {
      border: none;
      padding: 0;
    }

    // Additional fields
    > ul {
      li.ValidateDropdown  {

        padding: 0 0 2rem 0;

        fieldset {
          // display: flex;
          // flex-wrap: wrap;
          // align-items: center;

          .inline-tiny-mce {
            width: 100%;
            max-width: 700px;
            border: 2px solid #c5c5c5;
            padding: 0.5rem;
          }
        }
      }
    }

  }

}


.s4s-default ul#tabs {
  background: none;
  background-color: #07073f;
  margin: 0;
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  li {

    float: none;

    a {
      color: white;
      margin: 0;

      &.active {
        background-color: white;
        color: #07073f;
        border-radius: 0;
      }

      &:hover {
        background: none;
        text-decoration: underline;
      }
    }
  }
}

.s4s-default #tabsCapend {
  display: none;
}

.sc-event-documents .documents__editor{
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 2rem;
}

@media handheld,screen and (min-width: 320px) and (max-width:693px) {


  .s4s-default fieldset div.mce-tinymce{
    margin-left: 0;
    margin-top: 25px;
  }

  .s4s-default .event-details__postcode_btn{
    margin-left: 0;
  }

  .s4s-default ul#tabs li a {
    background: none;
  }

}
//}

	

/*--- AUTH login s4s Page  ---*/

.auth-page .title-container{
  text-align: center;
}

.auth-page .title-container h1{
  font-weight: 700;
}

.auth-page .contentFixed.loginPage {
    width: 90% !important;
    margin: auto 5%;
    padding: 0;
}

.auth-page #LoginBox {
    padding: 1rem;
    border-radius: 0px;
    box-shadow: none;
    background-color: #edf0f3;
    text-align: center;
    width: 80%;
    margin: 2rem 10%;
    display: flex;
    flex-direction: row;
}

.auth-page #LoginBox ul li{
	text-align: center;
    justify-content: center;
    display: flex;
}

.auth-page #signUpPromo {
    width: 50%;
    padding: 10px;
}

.auth-page #signUpPromo h2, .auth-page #signUpPromo p{
	font-size: 1.5rem;
    color: #000000;
    font-weight: normal;
}


.auth-page #signUpPromo div {
	margin: 0;
}


.auth-page div#logInPromo {
    width: 50%;
    padding: 10px;
}

.auth-page ul.actionButtons.grey.login,
.auth-page ul.actionButtons.login {
    margin: auto;
    float: none;
    padding: 5px;
    text-align: center;
}

.auth-page div#logInPromo h2 {
    height: 60px;
	font-size: 1.5rem;
    color: #000000;
    font-weight: normal;
}

.auth-page a#loginButtonId {
	font-size: unset;
  text-shadow: none;
  margin-right: 0;
  margin-top: 1rem;
  text-decoration: none;
  min-width: 15rem;
  border-radius: 0;
  max-width: 350px;
  color: #fff;
  text-align: center;
  background: #07073f;
  border: 2px solid #fff;
	font-weight: normal;
}

.auth-page a#loginButtonId:hover {
    text-decoration: underline;
}

.auth-page a#loginButtonId:focus-visible {
  text-decoration: underline;
}

.auth-page a#loginButtonId span{
	display: none;
}

.auth-page ul.actionButtons.login a {
	font-size: unset;
  text-shadow: none;
  margin-right: 0;
  margin-top: 1rem;
  padding: 10px 20px !important;
  text-decoration: none;
  min-width: 15rem;
  border: 2px solid #ffffff;
  border-radius: 0;
  background: #fff;
  max-width: 350px;
  color: #07073f;
  font-weight: normal;
}

.auth-page ul.actionButtons.login a:hover {
  text-decoration: underline;
	color: #07073f;
}

.auth-page ul.actionButtons.login a:focus-visible {
  text-decoration: underline;
	color: #07073f;
}

.auth-page ul.actionButtons.login a span{
	display: none;
}

.auth-page .contentWrapper.loginBackground{
	background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


@media handheld,screen and (min-width: 320px) and (max-width: 1024px) {
  .auth-page div#logInPromo,
  .auth-page #signUpPromo {
    padding: 0px;
    width: 100%;
		border-left: 0;
  }

  .auth-page div#logInPromo h2 {
      height: auto;
  }

  .auth-page ul.actionButtons li {
      margin: 2rem 0;
  }

	.auth-page #signUpPromo {
    border-top: 1px solid #ccc;
		height: auto;
  }

  .auth-page #signUpPromo div {
    margin: 0;
  }

  .auth-page a#loginButtonId,
  .auth-page ul.actionButtons.login a {
    min-width: 5rem;
  }

	.auth-page #LoginBox {
		display: flex;
		flex-direction: column;
	}

	.auth-page ul.actionButtons.grey.login,
	.auth-page ul.actionButtons.login {
		width: 100%;
	}

	.auth-page .contentFixed.loginPage {
		margin: 0;
	}

	#administrationExcerpt{
		width: 100%;
	}

	#maincontent{
		padding: 1rem;
	}
}

/* Direct Provider Registration Form begins */

.directProviderRegistration .form-main {
  display: flex;
  flex-direction: column;
}

.directProviderRegistration .forms-mandatory {
	color: #EB0000 !important;
}

.directProviderRegistration .js-ppl-registration-form-container {
  max-width: 960px;
  min-width: auto;
  margin: 0 auto;
}

.directProviderRegistration .form-header {
  padding: 30px 0;
  background-color: #f0f0f0;
  margin-bottom: 2rem;
}

.directProviderRegistration .navigation-bar__container {
  margin: 50px auto 25px !important;
}

.directProviderRegistration .form-main__questions-container {
  width: 100% !important;
  margin: 0 !important;
  background: #f0f0f0 !important;
}

.directProviderRegistration .characters-countdown-input_label {
  text-align: left;
  margin-left: 20px; 
  color: #000000 !important;
}

.directProviderRegistration .form-main__questions-container>div {
  margin-bottom: 50px !important;  
}

.directProviderRegistration .form-checkbox__option {
  padding-top: 5px;
  padding-left: 5px;
}
	
.directProviderRegistration .question-type-text,
.directProviderRegistration .question-type-date,
.directProviderRegistration .question-type-radio,
.directProviderRegistration .question-type-email,
.directProviderRegistration .question-type-password,
.directProviderRegistration .question-type-dropdownlist,
.directProviderRegistration .question-type-checkboxlist,
.directProviderRegistration .question-type-phone,
.directProviderRegistration .question-type-address {
  margin-left: 20px;
}




.directProviderRegistration .helpbox-container {
  margin-left: 0;
}

.directProviderRegistration .question-type-label p > div > p:first-child {
  background-color: #07073f !important;
  box-shadow: none !important;
}

.directProviderRegistration .question-type-label p > div > p:nth-child(2) {
  background-color: #07073f !important;
  box-shadow: none !important;
}

.directProviderRegistration .question-type-label:first-child p > div > p:nth-child(2) {
	background-color: #07073f !important;
}

.directProviderRegistration .form-navigation-btns__container {
  padding: 32px 8px !important;
  text-align: right;
  background: #ffffff !important;
  box-shadow: none !important;
}

.directProviderRegistration .form-navigation-btns__button{
  background-color: #07073f !important;
  border: none !important;
  color: white !important;
  padding: 0.5rem 2rem !important;
  text-decoration: none !important;
  font-size: 1.2rem !important;
  font-weight: normal !important;
  text-shadow: none !important;
}

.directProviderRegistration [type=radio]:checked+label:after,
.directProviderRegistration [type=checkbox]:checked+label:after {
  height: 10px !important;
  left: 4px !important;
  top: 4px !important;
  width: 10px !important;
}


.directProviderRegistration #maincontent{
	margin-bottom: 3px;
    // height: 100%;
    // position: relative;
  }

.directProviderRegistration .js-ppl-registration-captcha{
	background-color: #07073f;
    border-top: none;
    max-width: 960px;
    min-width: auto;
    margin: 0 auto;
	position: relative;
	display: block;
}

.directProviderRegistration .pcg-search-page__content-wrapper{
    display: flex;
    flex-direction: column-reverse;
}



@media handheld, screen and (max-width: 768px) and (min-width: 320px) {

  .directProviderRegistration .question-type-text,
  .directProviderRegistration .question-type-date,
  .directProviderRegistration .question-type-radio,
  .directProviderRegistration .question-type-email,
  .directProviderRegistration .question-type-password,
  .directProviderRegistration .question-type-dropdownlist,
  .directProviderRegistration .question-type-checkboxlist,
  .directProviderRegistration .question-type-phone,
  .directProviderRegistration .question-type-address {
	margin-left: 0px;
  }


  .directProviderRegistration .form-body{
	padding: 0 1rem;
  }

}


/* Direct Provider Registration Form ends */


// Pre-registration begins


.preRegistration .pcgf-default .pcgf-app, .pcgf-default a, .pcgf-default button, .pcgf-default h1, .pcgf-default h2, .pcgf-default h3, .pcgf-default label, .pcgf-default p, .pcgf-default span, .pcgf-default td, .pcgf-default th{
	font-family: 'Noto Sans', sans-serif !important;
}

.preRegistration .form-main {
  display: flex;
  flex-direction: column;
}

.preRegistration .forms-mandatory {
	color: #EB0000 !important;
}

.preRegistration .js-ppl-registration-form-container {
  max-width: 960px;
  min-width: auto;
  margin: 0 auto;
}

.preRegistration .form-header {
  padding: 30px 0;

}

.preRegistration .navigation-bar__container {
  margin: 50px auto 25px !important;
  width: auto;
}

.preRegistration .form-main__questions-container {
  width: 100% !important;
  margin: 0 !important;
//   padding: 1rem !important;

}

.preRegistration .characters-countdown-input_label {
  text-align: left;
  color: #000000 !important;
  position: relative;
}

.preRegistration .form-main__questions-container>div {
	// margin-bottom: 30px !important;
  display: flex;
  flex-direction: column;
  max-width: 75%; 
  padding: 0;
  margin-bottom: 2rem;
  margin-left: 0;
}

.preRegistration .form-checkbox__option {
  padding-top: 5px;
  padding-left: 5px;
}
	
.preRegistration .question-type-text,
.preRegistration .question-type-date,
.preRegistration .question-type-radio,
.preRegistration .question-type-email,
.preRegistration .question-type-password,
.preRegistration .question-type-dropdownlist,
.preRegistration .question-type-checkboxlist,
.preRegistration .question-type-phone,
.preRegistration .question-type-address {
  margin-left: 20px;
}




.preRegistration .helpbox-container {
  margin-left: 0;
}

.preRegistration .question-type-label p > div > p:first-child {
  background-color: #07073f!important;
  box-shadow: none !important;
}

.preRegistration .question-type-label p > div > p:nth-child(2) {
  /* background-color: #07073f !important; */
  box-shadow: none !important;
}

.preRegistration .question-type-label:first-child p > div > p:nth-child(2) {
	background-color: #07073f !important;
}

.preRegistration .form-navigation-btns__container {
  padding: 32px 0 !important;
  background: #ffffff !important;
  box-shadow: none !important;
}

.preRegistration .form-navigation-btns__button{
  background-color: #070707 !important;
  border: none !important;
  color: white !important;
  padding: 0.5rem 2rem !important;
  text-decoration: none !important;
  font-size: 1.2rem !important;
  font-weight: normal !important;
  text-shadow: none !important;
}

.preRegistration [type=radio]:checked+label:after,
.preRegistration [type=checkbox]:checked+label:after {
  height: 10px !important;
  left: 5px !important;
  top: 5px !important;
  width: 10px !important;
}


.preRegistration #maincontent{
	margin-bottom: 3px;
}

.preRegistration .navigation-bar__item__label {
    width: 100%;
    display: block;
}

.preRegistration .form-radio__option{
	margin-bottom: 0.5rem;
}

.preRegistration .form-control--valid p{
	margin-left: 20px;
}

// .preRegistration .pcg-jumbotron{
// 	background-color: #f0f0f0;
// }

.preRegistration .forms-text-list__container{
	margin-top: 1rem;
}


@media handheld, screen and (max-width: 768px) and (min-width: 320px) {

  .preRegistration .question-type-text,
  .preRegistration .question-type-date,
  .preRegistration .question-type-radio,
  .preRegistration .question-type-email,
  .preRegistration .question-type-password,
  .preRegistration .question-type-dropdownlist,
  .preRegistration .question-type-checkboxlist,
  .preRegistration .question-type-phone,
  .preRegistration .question-type-address {
	margin-left: 0px;
  }

  .preRegistration .form-body{
	padding: 0 1rem;
  }


}

/* Progress bar begins */



.preRegistration .navigation-bar__item_visited .navigation-bar__item__icon-holder i {
  background: #07073f !important;
}

.preRegistration .navigation-bar__item_current .navigation-bar__item__icon-holder i {
  background: #f0f0f0 !important;
}


.preRegistration .navigation-bar__item__icon-holder i {
  padding: 1px 1px 0 0;
  height: 44px !important;
  width: 44px !important;
  margin: 0 auto 34px;

}

.preRegistration .navigation-bar__item__inner-progress {
  border: none !important;
  height: 44px !important;
  width: 44px !important;
  margin: -48px auto 10px !important;
}

.preRegistration .navigation-bar__progress-container {
	top: 15px !important;
}

.preRegistration .navigation-bar__progress-bar {
  background: #07073f !important;
}

.preRegistration .navigation-bar__item__inner-progress_p100:before {
  content: '' !important;
}

.preRegistration .navigation-bar__items-container {
  padding-left: 0 !important;
}

.preRegistration .fa-circle:before {
  content: '' !important;
}

.preRegistration .navigation-bar__item.navigation-bar__item_completed:before  {
  border: none !important;
  color: #07073f !important;
  font-size: 1.1em !important;
  text-align: left !important;
  height: 20px !important;
  width: 23px !important;
  padding-left: 2px !important;
  margin-left: 5px !important;
  margin-top: -7px;
}

 .js-ppl-registration-captcha{
	background-color: #fff !important;
	color: #000 !important;
	display: flex;
	font-family: 'Noto Sans', sans-serif !important;
	border-top:  none !important;
	max-width: 960px;
	min-width: auto;
	margin: 0 auto;
	padding: 0 !important;
	position: relative !important;
	/* bottom: 189px !important; */
}

.js-captcha-container:before{
	color: #000 !important;
}

.pcg-search-page__content-wrapper {
	display: flex;
	flex-direction: column-reverse;
}

.pcgf-default .form-main__questions-container table th{
	background: #07073f !important;
	font-family: 'Noto Sans', sans-serif !important;
}

.pcgf-default .form-main__questions-container table{
	font-family: 'Noto Sans', sans-serif !important;
}

.preRegistration .container {
  padding: 0;
}

.preRegistration .pcg-content-jumbotron__page-title {
  padding: 0;
}

.preRegistration .pcg-jumbotron-title__line {
	display: block;
  padding: 30px 0;
}

.preRegistration .pcg-base-card{
	display: flex;
    justify-content: space-between;
	margin: 2rem 0;
}

.preRegistration button.form-btn.primary{
	display: inline-block;
    padding: .625rem 1.625rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    background: #070707 !important;
    border-radius: 0;
	text-decoration: none;
}

.preRegistration button.form-btn.primary:hover{
	text-decoration: underline;
}

.preRegistration .form-navigation-btns__btn_save, .preRegistration .form-navigation-btns__btn_previous{
	margin-right: 1rem;
}

.preRegistration .form-navigation-btns__container button:hover{
	text-decoration: underline;
}

.preRegistration .form-text-list__action-btns{
	margin-top: 0.5rem;
}





@media handheld, screen and (max-width: 768px) and (min-width: 320px) {

  .pcg-footer__copyright{
    text-align: center;
  }

  .preRegistration .navigation-bar__items-container {
	width: 400px !important;
	margin: auto !important;
	display: flex !important;
	flex-direction: column !important;
  }

  .preRegistration .navigation-bar__progress-container,
  .preRegistration .navigation-bar__item__inner-progress {
	display: none !important;
  }

  .preRegistration .navigation-bar__item__icon-holder {
	display: flex;
	flex-direction: row;
	margin-bottom: 30px;
  }

  .preRegistration .navigation-bar__item__icon-holder i {
	padding: 1px 7px 0px 7px;
	border: 6px solid #f1f1f1;
	background-color: #fff;
	text-align: center;
	color: #330072;
	font-size: 2em;
	line-height: 0.9;
	margin: auto 3rem auto 1.2rem !important;
	display: inline-block;
	height: 46px !important;
	width: 46px !important;
	margin-left: 25%;
	z-index: 2;
  }
  
  .preRegistration .navigation-bar__item__label {
	font-size: 20px;
	padding: 10px 0 67px 25px ;
	line-height: normal;
	text-align: left;
	position: absolute;;
	margin-left: 66px !important;
	margin-top: 1px !important;
  }

  .navigation-bar__item_progressed {
	  border-left: 10px solid #07073f!important;
	}

  .preRegistration .navigation-bar__item_visited .navigation-bar__item__icon-holder i {
	background: #07073f !important;
  }
  
  .preRegistration .navigation-bar__item_current .navigation-bar__item__icon-holder i {
	background: #f0f0f0 !important;
  }

  .preRegistration .navigation-bar__item.navigation-bar__item_completed:before  {
	left: 9% !important;
	z-index: 3;
	margin-left: 15px !important;
	margin-top: 0;
  }

}

@media handheld, screen and (max-width: 694px) and (min-width: 320px) {

  .s4s-default {

    ul.actionButtons {
      text-align: right;
    }

    .gridrow,
    .gridrow_alternate {
      margin-bottom: 2rem;
      border-left: 1px solid #ecebeb;

      td {
        &:first-child {
          display: flex;
          gap: 1rem;
          align-items: center;
          padding-top: 7px !important;
          box-shadow: none !important;
          background: #ecebeb;

          a {
            padding: 0.2rem 0.4rem;
            background-color: white;
            border-radius: 0.4rem;

            img {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .one-column-layout {
    padding: 0;
    margin: 1rem 0;
  }
}

.validation-summary-errors:focus{
  border: #52a8ecb3 solid 3px;
}

/* Required Asterisk */
.required-asterisk, .sc-password-rules li.sc-password-rules__criterion--invalid{
  color: #DE0602 !important;
}

@media handheld, screen and (max-width: 370px) and (min-width: 320px) {
  .preRegistration .navigation-bar__item__label {
	text-align: left !important;
	width: 220px;
  }
}


.alt-focus{
  border: 2px solid #000 !important;
}

/* Progress bar ends */

// Pre-registration ends

// MyProfileTab
#myAccountExcerpt h1{
  color: #07073f !important;
}

.myprofile-save-button{
  border: 2px solid #07073f;
  text-shadow: none !important;
  font-weight: normal !important;
  padding: 7px 20px 6px 20px;
  background: none !important;
  background-color: #07073f !important;
  color: #fff !important;
  border-radius: 0 !important;
  font-size: 1rem;
}


// My profile hiding elements
.s4s-default ul#tabs li a[href="/s4s/MyProfile/AdditionalInformationTab"],
.s4s-default ul#tabs li a[href="/s4s/MyProfile/AskTheExpertDetailsTab"] {
  display: none;
}

// Color contrast
.alt-contrast {
  background-color:#3a3a3a !important;
  
  h1, h2, h3, h4, h5, h6, .listing-guidance, .listing-guidance p{
    color:#ff0 !important
  } 
  time{color:#ff0 !important
  } 
  .pcg-contrast__bg-main{
    background-color:#000 !important
  } 
  .pcg-contrast__bg-alt{
    background-color:#3a3a3a !important
  } 
  .pcg-contrast__bg-extra{
    background-color:#1f1f1f !important
  } 
  .pcg-contrast__bg-addit{
    background-color:#00e1e8 !important
  } 
  .pcg-contrast__color-main, .pcg-contrast__color-main--before:before, .pcg-contrast__color-main--after:after{
    color:#00e1e8 !important;
    text-shadow:none;
  } 
  .pcg-contrast__color-alt, .pcg-contrast__color-alt--before:before, .pcg-contrast__color-alt--after:after{
    color:#ff0 !important} 
  .pcg-contrast__color-extra, .pcg-contrast__color-extra--before:before, .pcg-contrast__color-extra--after:after{
    color:#fff !important
  } 
  .pcg-contrast__border-main{
    border-color:#00e1e8 !important
  }
  .pcg-contrast__border-alt{
    border-color:#ff0 !important
  } 
  .pcg-contrast__link, .pcg-contrast-translate .goog-te-gadget-simple a{
    color:#00e1e8 !important;text-decoration:underline !important
  } 
  .pcg-contrast__link:hover, .pcg-contrast-translate .goog-te-gadget-simple a:hover, .pcg-contrast__link:focus, 
  .pcg-contrast-translate .goog-te-gadget-simple a:focus{
    color:#ff0 !important;text-decoration:none !important
  }
  .pcg-contrast__link-hovered, .pcg-contrast-accessibility .pcg-accessibility__scheme, .pcg-contrast-accessibility .pcg-accessibility__size{
    color:#00e1e8 !important;text-decoration:underline !important
  } 
  .pcg-contrast__link-hovered:hover, .pcg-contrast-accessibility .pcg-accessibility__scheme:hover, 
  .pcg-contrast-accessibility .pcg-accessibility__size:hover, .pcg-contrast__link-hovered:focus, 
  .pcg-contrast-accessibility .pcg-accessibility__scheme:focus, .pcg-contrast-accessibility .pcg-accessibility__size:focus{
    color:#000 !important;text-decoration:none !important;background-color:#00e1e8 !important
  } 
  .pcg-contrast__btn{
    color:#00e1e8 !important;background-color:#3a3a3a !important;border:2px solid #00e1e8 !important
  } 
  .pcg-contrast__btn:hover, .pcg-contrast__btn:active, .pcg-contrast__btn:focus{
    text-decoration:underline !important
  } 
  .pcg-contrast-rte a{
    color:#00e1e8 !important;text-decoration:underline !important
  } 
  .pcg-contrast-rte a:hover{
    color:#000 !important;text-decoration:none !important;background-color:#00e1e8 !important
  } 
  .pcg-contrast-rte p, .pcg-contrast-rte ol, .pcg-contrast-rte ul, .pcg-contrast-rte th{
    color:#00e1e8 !important
  } 
  .pcg-contrast-accessibility .pcg-accessibility__scheme{
    border-color:#00e1e8 !important
  } 
  .pcg-contrast-accessibility .pcg-accessibility__scheme.contrast{
    background-color:#1f1f1f !important
  } 
  .pcg-contrast-accessibility .pcg-accessibility__scheme.contrast:hover, .pcg-contrast-accessibility .pcg-accessibility__scheme.contrast:active, 
  .pcg-contrast-accessibility .pcg-accessibility__scheme.contrast:focus{
    color:#00e1e8 !important
  } 
  .pcg-contrast-accessibility .pcg-accessibility__scheme.normal{
    color:#3a3a3a !important;background-color:#00e1e8 !important
  } 
  .pcg-contrast-translate .skiptranslate.goog-te-gadget>div::before{
    color:#00e1e8 !important
  } 
  .pcg-contrast-translate .skiptranslate.goog-te-gadget .goog-te-combo{
    color:#00e1e8 !important
  } 
  .pcg-contrast-main-slider .slick-arrow{
    background-color:#000 !important
  } 
  .pcg-contrast-main-slider .slick-prev::before, .pcg-contrast-main-slider .slick-next::before{
    color:#00e1e8 !important
  } 
  .pcg-contrast-main-slider .slick-dots button{
    background-color:#00e1e8 !important
  } 
  .pcg-contrast-main-slider .slick-dots .slick-active button{
    background-color:#ff0 !important
  } 
  .pcg-contrast-filter-collapsible .pcg-filter-collapsible__btn{
    color:#00e1e8 !important;border-top-color:#00e1e8 !important
  } 
  .pcg-contrast-filter-collapsible .pcg-filter-collapsible__btn::after{
    color:#00e1e8 !important
  } 
  .pcg-contrast-filter-checkbox .pcg-filter-checkbox__text{
    color:#00e1e8
  } 
  .pcg-contrast-filter-checkbox .pcg-filter-checkbox__text::before{
    border-color:#00e1e8 !important
  } 
  .pcg-contrast-filter-checkbox .pcg-filter-checkbox__text::after{
    border-color:#00e1e8 !important
  } 
  .pcg-contrast-filter-checkbox input[type=checkbox]:focus+.pcg-filter-checkbox__text::before{
    border-color:#ff0 !important
  } 
  .pcg-contrast-search-bar .pcg-search-bar__input{
    color:#00e1e8 !important;background:#1f1f1f !important;border-color:#00e1e8 !important
  } 
  .pcg-contrast-search-bar .pcg-search-bar__input::-webkit-input-placeholder{
    color:#00e1e8 !important
  } 
  .pcg-contrast-search-bar .pcg-search-bar__input::-moz-placeholder{
    color:#00e1e8 !important
  } 
  .pcg-contrast-search-bar .pcg-search-bar__input:-ms-input-placeholder{
    color:#00e1e8 !important
  } 
  .pcg-contrast-search-bar .pcg-search-bar__input::-ms-input-placeholder{
    color:#00e1e8 !important
  } 
  .pcg-contrast-search-bar .pcg-search-bar__input::placeholder{
    color:#00e1e8 !important
  } 
  .pcg-contrast-search-bar .pcg-search-bar__submit{
    color:#00e1e8 !important;background:#000 !important
  } 
  .pcg-contrast-quote-block{
    background-color:#000 !important
  } 
  .pcg-contrast-quote-block .pcg-quote-block__content{
    color:#ff0 !important;border-left-color:#00e1e8 !important
  } 
  .pcg-contrast-quote-block .pcg-quote-block__content a{
    text-decoration:underline !important;color:#ff0 !important
  } 
  .pcg-contrast-quote-block .pcg-content-block-title{
    color:#ff0 !important
  } 
  .pcg-contrast-collapsible-block .pcg-collapsible-block__btn{
    color:#ff0 !important;background-color:#000 !important;border-color:#000 !important
  }
  .pcg-contrast-collapsible-block .pcg-collapsible-block__btn--inverted{
    background-color:#3a3a3a !important;border-color:#00e1e8 !important
  } 
  .pcg-contrast-collapsible-block .pcg-collapsible-block__content{
    border-color:#00e1e8 !important
  }

  .form label, .form legend, .sc-password-rules span{
    color:#ff0 !important;
    text-shadow: none;
  }

  .entityForm label, .entityForm legend, .entityForm .photo-proportions img, .entityForm input[name*="UserPicUploadImage"] {
    color:#ff0 !important;
    text-shadow: none;
  }

  .validation-summary-errors h1{
    color: #000 !important
  }

  .alt-back{
    background-color: #3a3a3a !important;
    box-shadow: 600px 0px 0px 0px #3a3a3a;
    &:before{
      box-shadow: -600px 0px 0px 0px #3a3a3a;
    }
  }

  .dashboard-body__container{
    background-color: #3a3a3a !important;
  }

  .shortlist-notification-container{
    border: 2px solid #00e1e8 !important;
    background-color: #3a3a3a !important;

    span{
      color:#00e1e8 !important;
    }
  }

  .attr-container{
    .dropdown{
      background-color: #3a3a3a !important;    
      li{

        .selected{
          &:after{
            color:#00e1e8 !important;
          }
        }

        button{
          &:after{
            color:#00e1e8 !important;
            border-color: #00e1e8 !important;
          }
        }

        &:after{
          color:#00e1e8 !important;
        }

        &:hover{
          background-color: #3a3a3a !important;
        }
      }
      
    }

    .sort-container{
      background-color: #3a3a3a !important;   
      ul{
        background-color: #3a3a3a !important;

        li{
          background-color: #3a3a3a !important;
          .selected{
            &:after{
              color:#00e1e8 !important;
            }
          }
  
          button{
            &:after{
              color:#00e1e8 !important;
              border-color: #00e1e8 !important;
            }
          }
  
          &:after{
            color:#00e1e8 !important;
          }
  
          &:hover{
            background-color: #3a3a3a !important;
          }
        }
      }       
    }
  }

  .active-view{
    outline: 2px solid #00e1e8 !important;

    .fas{
      padding: 5px !important;
    } 
  }

  .searchbar{
    button{
      color: #00e1e8 !important;
      background-color: #3a3a3a !important;
      border: 2px solid #00e1e8 !important;

      &:after{
        color: #00e1e8 !important;
      }
    }
    
  }

  .required-asterisk, .sc-password-rules li.sc-password-rules__criterion--invalid{
    color: #FC1510 !important;
  }

  @media handheld, screen and (max-width: 768px) and (min-width: 320px) {
    .alt-back{
      background-color: #3a3a3a !important;
      box-shadow: 300px 0px 0px 0px #3a3a3a;
      &:before{
        box-shadow: -300px 0px 0px 0px #3a3a3a;
      }
    }  
  }



  .star-ratings {
    fieldset {
      .form-radio__option {
        &.selected {
          [type="radio"]+label:before {
            color:#ff0 !important
          }
          [type="radio"]:checked+label:before {
            color:#ff0 !important
          }
        }
      }
    }
  }

  .star-container {
    .star {
        &.selected {
            &:before {
              color:#ff0 !important
            }
        }
    }
  }

  .alt-focus{
    border: 2px solid #ff0 !important;
  }

  .model-box{
    .header{
      div{
        p{
          color: #00e1e8 !important;
        }
      }
    }

    p{
      color: #00e1e8 !important;
    }
  }

  .listingRemoval {
    .section{
      color: #00e1e8 !important;
    }

    .custom-form-answer{
      p {
        span{
          color: #00e1e8 !important;
        }
      }
    }

    #lg_id1{
      color: #00e1e8 !important;
    }

    
  }

  

}

// Footer
footer{
  .pcg-footer-nav__item{
    a{
      text-decoration: underline; 
      &:hover{
        text-decoration: none !important;
      }

      &:focus-visible{
        border: 1px solid;
        text-decoration: none;
      }
    }
  }
}


// Listing Request Removal Form
.listingRemoval{
  .form-navigation-btns__btn_save {
    display: none;
  }

  .mce-container, .mce-container-body{
    display: flex;
    flex-direction: column;
  }


  #mceu_20, #mceu_21, #mceu_22, #mceu_23, #mceu_24, #mceu_25{
    display: none;
  }

}

.listingResult{

  margin-bottom: 2rem;
  .custom-question:nth-child(3) {
    display: none;
  }

  .custom-question:nth-child(4) {
    display: none;
  }

  .form-header__action-buttons{
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
  }

  .form-header__action-buttons button {
    background-color: #07073f;
    border: 2px solid #07073f;
    color: #fff;
    padding: .5rem 2rem;
    text-shadow: none;
    border-radius: 0;
  }

  .form-header__action-buttons button:last-child, .form-header__action-buttons button:nth-child(2) {
    display: none;
  }

  .col {
      margin: 0 !important;
      text-align: center;
      display: contents;
  }

  .span_1_of_12{
    width: 0 !important;
  }
}

.listingResultViewForm{

  margin-bottom: 2rem;

  .form-header__action-buttons{
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
  }

  .form-header__action-buttons button {
    background-color: #07073f;
    border: 2px solid #07073f;
    color: #fff;
    padding: .5rem 2rem;
    text-shadow: none;
    border-radius: 0;
  }

  .form-header__action-buttons button:last-child, .form-header__action-buttons button:nth-child(2) {
    display: none;
  }

  .col {
      margin: 0 !important;
      text-align: center;
      display: contents;
  }

  .span_1_of_12{
    width: 0 !important;
  }

  .custom-form-answer{
    p{
      float: left !important;
    }
  }
}